import React from 'react';

import ShowcasePage from '../../../components/ShowcasePage';

const Showcase = () => (
  <ShowcasePage
    totalItems={25}
    s3Path={`portfolio/weeding/1/`}
  />
)

export default Showcase;
